import client from "../api";

const base = "dashboard-paynow";

export function adminTransactionVolume(): Promise<any> {
    return client.get(`${base}/admin-transaction-volume`, {});
}

export function revenueGenerated(): Promise<any> {
    return client.get(`${base}/revenue-generated`, {});
}

// Route.get("school-pending-transactions", "DashboardPaynowController.schoolPendingTransactions");
export function schoolPendingTransactions(): Promise<any> {
    return client.get(`${base}/school-pending-transactions`, {});
}

const dashboardPaynow = {
    adminTransactionVolume,
    revenueGenerated,
    schoolPendingTransactions
}

export default dashboardPaynow;