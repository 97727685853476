import { useQuery } from "@tanstack/react-query";
import { Card, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loader from "react-loaders";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import dashboardPaynow from "utils/api/dashboard-paynow";

const SchoolPendingTransactions = () => {
    const { t } = useTranslation(["plans"]);
    const { data, isFetching } = useQuery(
        ["dashboard-paynow", { endpoint: "schoolPendingTransactions" }],
        () => dashboardPaynow.schoolPendingTransactions(),
        {
            placeholderData: {
                amount: 0
            }
        }
    );

    return (
        <Col lg={6} className="mb-3">
            <LoadingOverlayWrapper
                active={isFetching}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "#fff",
                        opacity: 0.5,
                    }),
                }}
                spinner={<Loader active type="ball-pulse" />}
            >
                <Card>
                    <Card.Body className="p-4">
                        <p className="text-graycool fs-7 mb-3">
                            {t("pending_transactions")}
                        </p>
                        <h3 className="text-success fw-bold">
                            {data?.amount?.toLocaleString(undefined, {
                                maximumFractionDigits: 0,
                            })}
                            <sup className="fs-5"> {t("kd")}</sup>
                        </h3>
                    </Card.Body>
                </Card>
            </LoadingOverlayWrapper>
        </Col>
    );
};

export default SchoolPendingTransactions; 