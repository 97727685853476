import { useQuery } from "@tanstack/react-query";
import cx from "classnames";
import { Field, Formik, Form as FormikForm } from "formik";
import { useState } from "react";
import { Button, Col, Container, Modal, Nav, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from "react-i18next";
import { schools } from "utils/api/schools";
import SchoolType from "utils/enums/SchoolType";
import { arabicValue } from "utils/functions/utilities";

type Props = {
  initialType: number;
  selectedSchool: number;
  schoolsData: any;
  selectSchool: (school: any) => void;
  handleClose: () => void;
};

const SelectSchool = (props: Props) => {
  const {
    handleClose,
    schoolsData,
    selectedSchool,
    selectSchool,
    initialType,
  } = props;
  const [showPartners, setshowPartners] = useState(true);
  const { t } = useTranslation(["plans", "common"]);

  function filterSchools({ search, type }: any) {
    let filteredSchools = schoolsData.filter(
      (school: any) => school.type == initialType && school.status == 0
    );

    if (search.length > 0) {
      filteredSchools = filteredSchools
        .filter(
          (school: any) =>
            school.name?.toLowerCase().includes(search.toLowerCase()) ||
            school.name_ar?.toLowerCase().includes(search.toLowerCase())
        )
        // .filter((school: any) =>
        //   !type ? school.isPartner : !school.isPartner
        // );
    } else {
      // filteredSchools = filteredSchools.filter((school: any) =>
      //   showPartners ? school.isPartner : !school.isPartner
      // );
    }

    return filteredSchools;
  }

  const filters = [
    {
      name: t("ruba_partners"),
      type: 0,
    },
    {
      name: t("non_partners"),
      type: 1,
    },
  ];

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      0% interest, descriptive text about the 0% interest.
    </Tooltip>
  );

  const renderTooltipNonPartner = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      <b>{t("non_partners")}</b>, descriptive text about non_partners schools.
    </Tooltip>
  );

  function submit(values: any) {
    handleClose();
  }

  return (
    <>
      <Formik onSubmit={submit} initialValues={{ search: "" }}>
        {(props) => (
          <FormikForm>
            <Container fluid>
              <Row className="mb-4">
                <Col lg={12} className="position-relative pt-4 pt-lg-0">
                  <i className="position-absolute mt-2 bi bi-search fs-5 text-black-50 ms-3 py-2"></i>
                  <Field
                    as={Form.Control}
                    name="search"
                    className="ps-5 mt-lg-0"
                    placeholder={t("plans:search_by_institutions", {
                      type: t("a_" + SchoolType[initialType].toLowerCase()),
                    })}
                    aria-label="Username"
                    aria-describedby="search"
                  />
                </Col>
              </Row>
              {props.values.search.length > 0 ? (
                <Row>
                  {/* <Col lg={4}>
                    <div
                      className={cx(
                        "d-flex flex-column justify-content-center rounded-3 p-3 category mb-3 active"
                      )}
                    >
                      <h6>{t("common:all")}</h6>
                      <p className="text-gray m-0">
                        {filterSchools({
                          search: props.values.search,
                          type: 0,
                        }).length +
                          filterSchools({
                            search: props.values.search,
                            type: 1,
                          }).length}{" "}
                        {t("a_" + SchoolType[initialType].toLowerCase())}{" "}
                        {t("common:found")}
                      </p>
                    </div>
                    <div
                      className={cx(
                        "d-flex flex-column justify-content-center rounded-3 p-3 category"
                      )}
                    >
                      <h6>{t("ruba_partners")}</h6>
                      <p className="text-gray m-0">
                        {
                          filterSchools({
                            search: props.values.search,
                            type: 0,
                          }).length
                        }{" "}
                        {t("a_" + SchoolType[initialType].toLowerCase())}{" "}
                        {t("common:found")}
                      </p>
                    </div>
                    <div
                      className={
                        "mt-3 d-flex flex-column justify-content-center rounded-3 p-3 category"
                      }
                    >
                      <h6>{t("non_partners")}</h6>
                      <p className="text-gray m-0">
                        {
                          filterSchools({
                            search: props.values.search,
                            type: 1,
                          }).length
                        }{" "}
                        {t("a_" + SchoolType[initialType].toLowerCase())}{" "}
                        {t("common:found")}
                      </p>
                    </div>
                  </Col> */}
                  <Col lg={12}>
                    {filters.map((filter) => (
                      <>
                        <div className="d-flex justify-content-between mt-4 mt-lg-0">
                          <h6 className="fw-semibold">{filter.name}</h6>
                          <p className="text-gray fw-light">
                            {`${
                              filterSchools({
                                search: props.values.search,
                                type: filter.type,
                              }).length
                            } `}
                            {t("a_" + SchoolType[initialType].toLowerCase())}{" "}
                            {t("common:available")}
                          </p>
                        </div>
                        <hr className="mt-0 text-gray" />
                        <div className="list overflow-auto">
                          {filterSchools({
                            search: props.values.search,
                            type: filter.type,
                          }).map((school: any) => (
                            <div
                              key={school.id}
                              onClick={() => selectSchool(school)}
                              className={cx(
                                "p-3 cursor-pointer rounded-3 mb-3 school",
                                {
                                  "border border-primary bg-info fw-semibold":
                                    school.id == selectedSchool,
                                }
                              )}
                            >
                              {arabicValue(school, "name")}
                            </div>
                          ))}
                        </div>
                      </>
                    ))}
                  </Col>
                </Row>
              ) : (
                <Row>
                  {/* <Col lg={4} className="d-none d-lg-block">
                    <div
                      onClick={() => setshowPartners(true)}
                      className={cx(
                        "d-flex flex-column justify-content-center rounded-3 p-3 category",
                        { active: showPartners }
                      )}
                    >
                      <h6>
                        {t("ruba_partners")}
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip}
                        >
                          <i className="text-gray opacity-75 mx-2 bi bi-info-circle"></i>
                        </OverlayTrigger>
                      </h6>
                      <p className="text-primary m-0">{t("zero_interest")}</p>
                    </div>
                    <div
                      onClick={() => setshowPartners(false)}
                      className={cx(
                        "mt-3 d-flex flex-column justify-content-center rounded-3 p-3 category",
                        { active: !showPartners }
                      )}
                    >
                      <h6>
                        {t("non_partners")}
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltipNonPartner}
                        >
                          <i className="text-gray opacity-75 mx-2 bi bi-info-circle"></i>
                        </OverlayTrigger>
                      </h6>
                      <p className="text-primary m-0">
                        {t("interest_applies")}
                      </p>
                    </div>
                  </Col>
                  <Col lg={4} className="d-block d-lg-none">
                    <Nav
                      className="gap-0"
                      variant="underline"
                      defaultActiveKey="1"
                    >
                      <Nav.Item
                        onClick={() => setshowPartners(true)}
                        className="flex-fill"
                      >
                        <Nav.Link eventKey="1" as="p">
                          {t("ruba_partners")}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        onClick={() => setshowPartners(false)}
                        className="flex-fill"
                      >
                        <Nav.Link eventKey="2" as="p">
                          {t("non_partners")}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col> */}
                  <Col lg={12}>
                    <div className="d-none d-lg-flex justify-content-between mt-4 mt-lg-0">
                      <h6 className="fw-semibold">
                        {showPartners ? t("ruba_partners") : t("non_partners")}
                      </h6>
                      <p className="text-gray fw-light">
                        {`${
                          filterSchools({ search: props.values.search }).length
                        } `}
                        {t("a_" + SchoolType[initialType].toLowerCase())}{" "}
                        {t("common:available")}
                      </p>
                    </div>
                    <hr className="mt-0 text-gray d-none d-lg-block" />
                    <div className="list overflow-auto">
                      {filterSchools({ search: props.values.search }).map(
                        (school: any) => (
                          <div
                            key={school.id}
                            onClick={() => selectSchool(school)}
                            className={cx(
                              "p-3 cursor-pointer rounded-1 mb-3 school",
                              {
                                "border border-primary bg-light-blue fw-semibold":
                                  school.id == selectedSchool,
                              }
                            )}
                          >
                            {arabicValue(school, "name")}
                          </div>
                        )
                      )}
                    </div>
                  </Col>
                </Row>
              )}
              <Row>
                <Col lg={12} className="d-flex justify-content-end">
                  <Button
                    onClick={() => {}}
                    variant="primary"
                    type="submit"
                    className="w-100 rounded-4 py-3 mt-3"
                    disabled={!selectedSchool}
                  >
                    {t("common:continue")}
                  </Button>
                </Col>
              </Row>
            </Container>
          </FormikForm>
        )}
      </Formik>
    </>
  );
};

export default SelectSchool;
