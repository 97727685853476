import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import plans from "utils/api/plans";
import PlanStatus from "utils/enums/PlanStatus";
import AdminPlanFileUploader from "./AdminActions/FileUploader";
import PaymentDuer from "./AdminActions/PaymentDuer";
import PlanAdminActionsForm from "./AdminActions/PlanPendingAdminActions";
import PlanPendingRubaSchoolTIDActionsForm from "./AdminActions/PlanPendingRubaSchoolTIDActionsForm";
import AdminPlanUpdateStatus from "./AdminActions/UpdateStatus";
import AdminPlanUpdateCuts from "./AdminActions/UpdateCuts";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import TransactionType from "utils/enums/TransactionType";
import ToggleDoNotContact from "./AdminActions/DoNotContact";

const PlanAdminActions = (props: { plan: any }) => {
  const [expanded, setExpanded] = useState(false);
  const { plan } = props;
  const client = useQueryClient();
  const { t } = useTranslation(["common"]);

  const planMutation = useMutation(
    (payload: { plan: any; values: any }) => {
      const { plan, values } = payload;
      return plans.updateStatus(plan.id, values);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(["plan"]);
        toast.success("Status updated");
      },
    }
  );

  return (
    <>
      {plan.status == PlanStatus.PENDING_RUBA && (
        <>
          <PlanAdminActionsForm planMutation={planMutation} plan={plan} />
          <hr />
        </>
      )}
      {plan.status == PlanStatus.PENDING_RUBA_SCHOOL_TID && (
        <>
          <PlanPendingRubaSchoolTIDActionsForm
            planMutation={planMutation}
            plan={plan}
          />
          <hr />
        </>
      )}
      <Row className="gap-3">
        <Col lg={12}>
          <AdminPlanUpdateStatus plan={plan} />
        </Col>
        <Col lg={12}>
          <AdminPlanUpdateCuts plan={plan} />
        </Col>
        <Col lg={12}>
          <AdminPlanFileUploader plan={plan} />
        </Col>
        <Col lg={12}>
          <Button
            variant="outline-light"
            onClick={() => setExpanded((prev) => !prev)}
            className="w-100 my-3 py-3"
          >
            {t(expanded ? "minimize_actions" : "more_actions")}{" "}
            <i className={`ms-2 bi bi-chevron-${expanded ? "up" : "down"}`}></i>
          </Button>
        </Col>
        {expanded && (
          <>
            {(!plan.transactions?.filter(
              (transaction: any) =>
                transaction.type != TransactionType.SERVICE_FEE
            ).length && !!plan.school.reserve_enabled) && <Col lg={12}>
                <Link to={`/reserve-transactions/${plan.id}/scheduler`}>
                  <Button variant="outline-primary" className="w-100">
                    {plan.extras?.reserve ? "Update" : "Create"} Reserve Plan
                  </Button>
                </Link>
              </Col>}
            <Col lg={12}>
              <Button
                variant="outline-primary"
                className="w-100"
                onClick={() => {
                  planMutation.mutate({
                    plan,
                    values: {
                      status: plan.status,
                      extras: {
                        ...plan.extras,
                        split: !plan.extras?.split ? true : !plan.extras?.split,
                      }
                    },
                  });
                }}
              >
                Toggle Split Flag
              </Button>
            </Col>
            <Col lg={12}>
              <ToggleDoNotContact mobile={plan.user?.mobile} />
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default PlanAdminActions;
