import { useQuery } from "@tanstack/react-query";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loader from "react-loaders";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import dashboardPaynow from "utils/api/dashboard-paynow";

const AdminPaynowRevenueGenerated = () => {
    const { t } = useTranslation(["plans"]);
    const { data, isFetching } = useQuery(
        ["dashboard-paynow", { endpoint: "revenueGenerated" }],
        () => dashboardPaynow.revenueGenerated(),
        {
            placeholderData: {
                ruba_cut: 0,
                hesabe_cut: 0
            }
        }
    );

    return (
        <Col lg={6} className="mb-3">
            <LoadingOverlayWrapper
                active={isFetching}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "#fff",
                        opacity: 0.5,
                    }),
                }}
                spinner={<Loader active type="ball-pulse" />}
            >
                <Card>
                    <Card.Body className="p-4">
                        <p className="text-graycool fs-7 mb-3">
                            {t("generated_revenue")}
                        </p>
                        <Row>
                            <Col>
                                <p className="text-graycool fs-7 mb-2">Ruba</p>
                                <h3 className="text-success fw-bold">
                                    {data?.ruba_cut?.toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    })}
                                    <sup className="fs-5"> {t("kd")}</sup>
                                </h3>
                            </Col>
                            <Col>
                                <p className="text-graycool fs-7 mb-2">Hesabe</p>
                                <h3 className="text-success fw-bold">
                                    {data?.hesabe_cut?.toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                    })}
                                    <sup className="fs-5"> {t("kd")}</sup>
                                </h3>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </LoadingOverlayWrapper>
        </Col>
    );
};

export default AdminPaynowRevenueGenerated; 