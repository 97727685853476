import client from "../api";

function list({
  queryKey,
  signal,
  ...params
}: any | undefined = undefined) {
  return client.get("plans", {
    params,
  }) as any;
}

function view(id?: string): Promise<any> {
  return client.get(`plans/${id}`);
}

async function store(values: any) {
  return client.post("plans", values) as any;
}

async function update(
  id: string | undefined,
  values: any
): Promise<any> {
  return client.put(`plans/${id}`, values) as any;
}

async function changeSchool(id: number, values: any) {
  return client.put(`plans/${id}/change-school`, values) as any;
}

async function destroy(id: number) {
  return client.delete(`plans/${id}`) as any;
}

async function updateStatus(
  id: number,
  values: { status: string; reason?: string, extras?: any, reserve_status?: string }
) {
  return client.put(`plans/${id}/update-status`, values) as any;
}

async function updateAmount(id: number, values: { amount: string }) {
  return client.put(`plans/${id}/update-amount`, values) as any;
}

async function availablePlan(
  schoolId: number,
  amount: number
): Promise<any> {
  return client.get("plans/available-plans", { params: { schoolId, amount } });
}

async function upload(props: any): Promise<any> {
  const { planId, files } = props;
  const data = new FormData();
  data.append("planId", planId);
  for (const [key, val] of Object.entries(files)) {
    data.append(`types[]`, key);
    data.append(`files[]`, val as Blob);
  }
  return await client.post("plans/upload", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "*/*",
    },
    onUploadProgress: (progressEvent: ProgressEvent) => { },
  });
}

async function note(id: number, note: any): Promise<any> {
  return client.put(`plans/${id}/note`, { note });
}

export function download(): Promise<any> {
  return client.get("plans/download");
}

async function generatedDocument(
  id: number,
  type: string,
  signed: boolean
) {
  return client.get(
    `plans/${id}/generate/${type}?${signed ? "signed=true" : ""}`,
    {
      responseType: "blob",
    }
  ) as any;
}

async function signCinet(id: number, confirmed = false): Promise<any> {
  return client.get(`plans/${id}/sign-cinet`, {
    responseType: "blob",
  });
}

async function filterables(): Promise<any> {
  return client.get(`plans/filterables`);
}

export async function updateCuts(
  id: number,
  values: { ruba_cut: number; fi_cut: number }
): Promise<any> {
  return client.put(`plans/${id}/update-cuts`, values);
}

export async function updatePaymentDue(
  id: number,
  values: { payment_due: string; }
): Promise<any> {
  return client.put(`plans/${id}/update-paymentdue`, values);
}

function transactions(filter: any): Promise<any> {
  const params = new URLSearchParams(filter).toString();
  return client.get(`plans/transactions?${params}`);
}

function pendingTransactions(): Promise<any> {
  return client.get(`plans/transactions/pending`);
}

function estimatedPayments(): Promise<any> {
  return client.get(`plans/estimated-payments`);
}

export const plans = {
  list,
  view,
  store,
  update,
  changeSchool,
  note,
  destroy,
  updateStatus,
  updateAmount,
  upload,
  availablePlan,
  download,
  generatedDocument,
  signCinet,
  filterables,
  updateCuts,
  updatePaymentDue,
  transactions,
  pendingTransactions,
  estimatedPayments,
};

export default plans;