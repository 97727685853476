import AdminBranchesByType from "../Widgets/AdminBranchesByType";
import AdminGMVWidget from "../Widgets/AdminGMV";
import AdminPendingActionsWidget from "../Widgets/AdminPendingActions";
import AdminServiceFeeStatsWidget from "../Widgets/AdminServiceFeeStats";
import AdminStatsWidget from "../Widgets/AdminStats";
import AdminStudentStatsWidget from "../Widgets/AdminStudentStats";
import AdminTotal from "../Widgets/AdminTotal";
import TopSchoolsWidget from "../Widgets/TopSchools";
import WelcomeWidget from "../Widgets/Welcome";

const AdminWidgets = () => {
  return (
    <>
      <WelcomeWidget />
      <AdminPendingActionsWidget />
      <AdminGMVWidget />
      <AdminStudentStatsWidget />
      <AdminServiceFeeStatsWidget />
      <AdminStatsWidget />
      <AdminTotal />
      <TopSchoolsWidget />
      <AdminBranchesByType />
    </>
  );
};

export default AdminWidgets;
