import Amount from "components/widgets/Amount";
import { DateTime } from "luxon";
import { useState } from "react";
import { Alert, Badge, Button, Modal, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { arabicValue, dateAndTime } from "utils/functions/utilities";

type Props = {
  showDate?: boolean
  plan: any
}

const EstimatedPaymentsTable = ({ plan, showDate = true }: Props) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation(["plans", "common"]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const renderPaymentDate = () => {
    if (!plan.payment_due) return null;

    const paymentDueDate = DateTime.fromFormat(plan.payment_due, "yyyy-MM-dd");
    const currentDate = DateTime.now();
    const lastPaymentDate = paymentDueDate.plus({ months: plan.number_of_installments });

    if (lastPaymentDate < currentDate) {
      return (
        <div className="d-flex align-items-center">
          <span className="text-muted">{t("no_upcoming_payments")}</span>
          <Button variant="link" className="p-0 ms-2" onClick={handleShow}>
            <i className="bi bi-calendar3"></i>
          </Button>
        </div>
      );
    }

    let nextPaymentDate: DateTime;

    if (currentDate.day < paymentDueDate.day) {
      nextPaymentDate = currentDate.set({ 
        day: paymentDueDate.day 
      });
    } else {
      nextPaymentDate = currentDate.plus({ months: 1 }).set({ 
        day: paymentDueDate.day 
      });
    }

    return (
      <div className="d-flex align-items-center">
        {showDate && <span className="me-2">{dateAndTime(nextPaymentDate.toFormat("yyyy-MM-dd"), true)}</span>}
        <Button variant="link" className="p-0 mx-2" onClick={handleShow}>
          <i className="bi bi-calendar3"></i>
        </Button>
      </div>
    );
  };

  const generateEstimatedPayments = () => {
    const payments = [];
    const startDate = DateTime.fromFormat(plan.payment_due, "yyyy-MM-dd");
    const currentDate = DateTime.now();

    for (let i = 0; i < plan.number_of_installments; i++) {
      const paymentDate = startDate.plus({ months: i });
      payments.push({
        date: paymentDate.toFormat("yyyy-MM-dd"),
        amount: plan.monthly_payment,
        isPast: paymentDate < currentDate
      });
    }

    return payments;
  };

  return (
    <>
      {renderPaymentDate()}

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t("estimated_payments")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped hover>
            <thead>
              <tr>
                <th>{t("common:date")}</th>
                <th>{t("plans:amount")}</th>
                <th>{t("plans:status")}</th>
              </tr>
            </thead>
            <tbody>
              {generateEstimatedPayments().map((payment, index) => (
                <tr key={index}>
                  <td>{dateAndTime(payment.date, true)}</td>
                  <td><Amount amount={payment.amount} /></td>
                  <td>
                    {payment.isPast ? (
                      <Badge bg="secondary" className="fbs-5">{t("plans:arrived")}</Badge>
                    ) : (
                      <Badge bg="warning" className="fbs-5">{t("plans:upcoming")}</Badge>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="text-muted mt-3 small">
            <i className="bi bi-info-circle mx-2"></i>
            {t("estimated_dates_note")}
          </div>
          {plan.fi?.location && (
            <Alert variant="info" className="mt-3">
              <h6 className="mb-2">
                <i className="bi bi-credit-card mx-2"></i>
                {t("payment_instructions")}
              </h6>
              <p className="mb-0">{arabicValue(plan.fi, "payment_methods")}</p>
            </Alert>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EstimatedPaymentsTable; 