import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import plans from "utils/api/plans";
import PlanStatus from "utils/enums/PlanStatus";
import AdminPlanFileUploader from "./AdminActions/FileUploader";
import PlanModeratorActionsForm from "./ModeratorActions/PlanPendingModeratorActions";
import { Link } from "react-router-dom";

const PlanModeratorActions = (props: { plan: any }) => {
  const [expanded, setExpanded] = useState(false);
  const { plan } = props;
  const { t } = useTranslation(["common"]);
  const client = useQueryClient();


  const planMutation = useMutation(
    (payload: { plan: any; values: any }) => {
      const { plan, values } = payload;
      return plans.updateStatus(plan.id, values);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(["plan"]);
        toast.success("Status updated");
      },
    }
  );

  return (
    <Row className="gy-3">
      {plan.status == PlanStatus.PENDING_RUBA && (
        <Col lg={12}>
          <PlanModeratorActionsForm plan={plan} planMutation={planMutation} />
        </Col>
      )}
      <Col lg={12}> <AdminPlanFileUploader plan={plan} /></Col>
      <Col lg={12}>
        <Button
          variant="outline-light"
          onClick={() => setExpanded((prev) => !prev)}
          className="w-100 my-3 py-3"
        >
          {t(expanded ? "minimize_actions" : "more_actions")}{" "}
          <i className={`ms-2 bi bi-chevron-${expanded ? "up" : "down"}`}></i>
        </Button>
      </Col>
      {expanded && (
        <Col lg={12}>
          <Link to={`/reserve-transactions/${plan.id}/scheduler`}>
            <Button variant="outline-primary" className="w-100">
              {plan.extras?.reserve ? "Update" : "Create"} Reserve Plan
            </Button>
          </Link>
        </Col>
      )}
    </Row>
  );
};

export default PlanModeratorActions;
