import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { Alert, Button, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { transactions } from "utils/api/transactions";
import TransactionStatus from "utils/enums/TransactionStatus";
import { usePaginate } from "utils/hooks/paginate";
import { useState } from "react";
import PaymentModal from "containers/Installments/List/PaymentModal";
import { paymentIsDone } from "utils/functions/utilities";
import TransactionType from "utils/enums/TransactionType";

type Props = {};

const PaymentSummaryOverdue = (props: Props) => {
  const { t } = useTranslation(["plans", "common"]);
  const [open, setOpen] = useState(false);
  const paginate = usePaginate({
    key: "transactions",
    api: transactions.list,
    filter: {
      type: Object.values(TransactionType).filter(type => type !== TransactionType.SERVICE_FEE)
    },
    options: {
      select: ({ data }: any) => {
        const overDue: any = [];
        const upcoming: any = [];
        let amountOverdue: number = 0;
        let amountUpcoming: number = 0;
        let amountRemaining: number = 0;
        let amountDueToday: number = 0;
        if (data?.length === 0) return null;
        data
          ?.filter(
            (item: any) =>
              !paymentIsDone(item)
          )
          .forEach((item: any) => {
            const currentDate = DateTime.fromISO(item.scheduled_date);
            const today = DateTime.local().startOf("day");
            const itsToday = today.toMillis() === currentDate.toMillis();
            if (itsToday) {
              amountDueToday += item.amount;
              overDue.push(item);
            } else if (currentDate < DateTime.now()) {
              if (item.status !== TransactionStatus.SUCCESS) {
                amountOverdue += item.amount;
                overDue.push(item);
              }
            } else {
              upcoming.push(item);
              if (DateTime.now().plus({ months: 1 }) > currentDate)
                amountUpcoming += item.amount;
            }
            amountRemaining += item.amount;
          });
        return {
          amountDueToday,
          amountOverdue,
          amountUpcoming,
          amountRemaining,
          overDue,
          upcoming,
        };
      },
    },
  });

  if (!paginate) return <></>;

  return (
    <>
      <PaymentModal open={open} setOpen={setOpen} />
      {paginate.data?.amountDueToday > 0 &&
        paginate.data?.amountOverdue == 0 && (
          <Col lg={4} className="mb-3">
            <Alert variant="primary" className="h-100 m-0">
              <div className="mb-3">{t("due_today")}</div>
              <div className="d-flex justify-content-between  fw-bold">
                <p className="fs-4">
                  {paginate.data?.amountDueToday.toLocaleString(undefined, {
                    minimumFractionDigits: 3,
                  })}
                  <sup className="mx-1">{t("common:kd")}</sup>
                </p>
                <Button
                  onClick={() => setOpen(true)}
                  variant="primary"
                  className="fbs-4 px-3"
                >
                  {t("pay_now")!}
                </Button>
              </div>
            </Alert>
          </Col>
        )}
      {paginate.data?.amountOverdue > 0 && (
        <Col lg={4} className="mb-3">
          <Alert variant="danger" className="h-100 m-0">
            <div className="mb-3">{t("overdue")}</div>
            <div className="d-flex justify-content-between  fw-bold">
              <p className="fs-4">
                {(paginate.data?.amountOverdue + paginate.data?.amountDueToday).toLocaleString(undefined, {
                  minimumFractionDigits: 3,
                })}
                <sup className="mx-1">{t("common:kd")}</sup>
              </p>
              <Button
                onClick={() => setOpen(true)}
                variant="danger"
                style={
                  {
                    backgroundColor: "#D92D20",
                    color: "white",
                    border: "none",
                  }
                }
                className="fbs-4 px-3"
              >
                {t("pay_now")!}
              </Button>
            </div>
          </Alert>
        </Col>
      )}
      {paginate.data?.amountUpcoming > 0 && (
        <Col lg={4} className="mb-3">
          <Alert variant="white" className="h-100 m-0">
            <div className="mb-3">{t("upcoming_in_x_days", { days: 30 })}</div>
            <div className="d-flex justify-content-between  fw-bold">
              <p className="fs-4 text-dark">
                {paginate.data?.amountUpcoming.toLocaleString(undefined, {
                  minimumFractionDigits: 3,
                })}
                <sup className="mx-1">{t("common:kd")}</sup>
              </p>
            </div>
          </Alert>
        </Col>
      )}
      {paginate.data?.amountRemaining > 0 && (
        <Col lg={4} className="mb-3">
          <Alert variant="white" className="h-100 m-0">
            <div className="mb-3">{t("remaining_installments")}</div>
            <div className="d-flex justify-content-between  fw-bold">
              <p className="fs-4 text-dark">
                {paginate.data?.amountRemaining.toLocaleString(undefined, {
                  minimumFractionDigits: 3,
                })}
                <sup className="mx-1">{t("common:kd")}</sup>
              </p>
            </div>
          </Alert>
        </Col>
      )}
    </>
  );
};

export default PaymentSummaryOverdue;
