import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
    size?: number | string
    currency?: string
    amount: number | string
    className?: string
}

const Amount = (props: Props) => {
    const { size = "sm", currency = "KD", amount, className = "" } = props;
    const { t } = useTranslation(['common']);
    let sizeClasses = "fbs-5";
    
    // Ensure amount is a number and format it with commas
    const formattedAmount = () => {
        if (amount === null || amount === undefined) return '0';
        const numericAmount = typeof amount === 'string' ? parseFloat(amount) : amount;
        return isNaN(numericAmount) ? '0' : numericAmount.toLocaleString();
    };
    
    return (
        <>
            <p className={className}>{formattedAmount()}
                <sup className={sizeClasses + " fw-bold"}> {t(currency.toLowerCase())}</sup>
            </p>
        </>
    )
}

export default Amount