import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Input from "components/inputs/Input";
import { Formik } from "formik";
import { useState } from "react";
import { Button, Card, Col, Modal, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { developers } from "utils/api/developer";
import { runReserveManager, settings } from "utils/api/settings";
import Role from "utils/enums/Role";
import useAlert from "utils/hooks/alert";
import { useUser } from "utils/hooks/auth";

const ListSettings = (props: any) => {
  const { data } = useQuery(["settings"], settings.list);
  const { t } = useTranslation(["common", "plans", "settings"]);
  const { data: user } = useUser();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const queryClient = useQueryClient();
  const { confirmOrDeny } = useAlert();

  const handleDevTool = (title: string, text: string, action: () => void, confirmText = "Yes, do it") => {
    confirmOrDeny({
      title: t(title),
      text: t(text),
      confirmButtonText: t(confirmText),
      onConfirm: action
    });
  };

  const handleReleaseTransactions = () => {
    const input = prompt("Enter transaction IDs (comma separated):");
    if (input) {
      const transactionIds = input.split(',').map(id => id.trim());
      handleDevTool(
        "Are you sure?",
        `This will release the following transactions: ${transactionIds.join(', ')}`,
        () => developers.releaseTransactions(transactionIds),
        "Yes, release them"
      );
    }
  };

  const devTools = [
    {
      title: "Settled but not transferred",
      confirmTitle: "Are you sure?",
      confirmText: "This will run all pending reminders",
      action: developers.settledButNotTransferred,
      variant: "secondary"
    },
    {
      title: "Reconcile transfer dates",
      confirmTitle: "Are you sure?",
      confirmText: "This will run all pending reminders",
      action: developers.reconcileTransferDates,
      variant: "secondary"
    },
    {
      title: "Release transactions",
      confirmTitle: "Are you sure?",
      confirmText: "Enter transaction IDs to release",
      action: handleReleaseTransactions,
      variant: "warning"
    },
    {
      title: "Run reserve manager",
      confirmTitle: "Are you sure?",
      confirmText: "This will run all pending reminders",
      action: runReserveManager,
      variant: "secondary"
    }
  ];

  const createMutation = useMutation(settings.store, {
    onSuccess: () => {
      queryClient.invalidateQueries(["settings"]);
      handleClose();
    },
  });

  const updateMutation = useMutation((payload: any) => settings.update(payload.id, payload.values), {
    onSuccess: () => {
      queryClient.invalidateQueries(["settings"]);
    },
  });


  if (user?.role_id != Role.ADMIN) {
    return <></>;
  }

  return (
    <Row>
      {/* Modal for setting creation */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Setting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{ key: "", value: "" }}
            onSubmit={(values) => {
              createMutation.mutate({
                key: values.key, value: JSON.parse(values.value)
              });
            }}
          >
            {({ values, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Row className="gy-4">
                  <Col lg={12}>
                    <Input
                      label="Key"
                      name="key"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.key}
                    />
                  </Col>

                  <Col lg={12}>
                    <Input
                      label="Value"
                      name="value"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.value}
                    />
                  </Col>
                  <Col lg={12}>
                    <Button type="submit">Create</Button>
                  </Col>
                </Row>

              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Col lg="12">
        <Card className="main-card mb-3">
          <Card.Body>
            {user?.role_id == Role.ADMIN && (
              <Row className="justify-content-end">
                <Col lg={3} className="mb-3 text-end">
                  <Button className="w-100" variant="primary" onClick={
                    settings.runReminders
                  }>
                    Run Reminders
                  </Button>
                </Col>
                <Col lg={3} className="mb-3 text-end">
                  <Button className="w-100" variant="primary" onClick={
                    () => setShow(true)
                  }>
                    Create New Setting
                  </Button>
                </Col>
              </Row>
            )}
            <Table className="mb-0">
              <thead>
                <tr>
                  <th>Key</th>
                  <th>Value</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item: any) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.key}</td>
                    <td>
                      <Formik
                        initialValues={{ value: JSON.stringify(item.value) }}
                        onSubmit={(values) => {
                          updateMutation.mutate({
                            id: item.id, values: {
                              value: JSON.parse(values.value)
                            }
                          });
                        }}
                      >
                        {({ values, handleChange, handleBlur, handleSubmit }) => (
                          <form onSubmit={handleSubmit}>
                            {/* hidden input key */}
                            <Input
                              type="textarea"
                              name="value"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <Button size="sm" type="submit">Save</Button>
                          </form>
                        )}
                      </Formik>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            
            {user?.role_id === Role.ADMIN && (
              <div className="mt-4">
                <h5 className="mb-3">Developer Tools <span className="text-danger fbs-4">( Please be careful with these tools )</span></h5>
                <Row className="g-2">
                  {devTools.map((tool, index) => (
                    <Col lg={3} key={index}>
                      <Button 
                        variant={tool.variant}
                        className="w-100" 
                        onClick={() => handleDevTool(
                          tool.confirmTitle,
                          tool.confirmText,
                          tool.action
                        )}
                      >
                        {tool.title}
                      </Button>
                    </Col>
                  ))}
                </Row>
              </div>
            )}
            
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ListSettings;
