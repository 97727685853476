import client from "../api";

export function list() {
    return client.get("settings") as any;
}

export function view(id: string | undefined) {
    return () => client.get(`settings/${id}`) as any
}

// viewByKey
export function viewByKey(key: string): Promise<any> {
    return client.get(`settings/key/${key}`) as any
}

export function store(values: any) {
    return client.post("settings", values) as any;
}

export function update(id: string, values: any): Promise<any> {
    return client.put(`settings/${id}`, values);
}

export function destroy(id: string | undefined) {
    return client.delete(`settings/${id}`) as any;
}

// run-reminders GET
export function runReminders(): Promise<any> {
    return client.get("settings/run-reminders") as any;
}

export function runReserveManager(): Promise<any> {
    return client.get("settings/run-reserve-manager") as any;
}

export const settings = {
    list,
    view,
    viewByKey,
    store,
    update,
    destroy,
    runReminders,
    runReserveManager
};
