import { useQuery } from "@tanstack/react-query";
import {
  flexRender,
  getCoreRowModel,
  useReactTable
} from "@tanstack/react-table";
import Input from "components/inputs/Input";
import Icon from "components/widgets/Icon";
import TablePages from "components/widgets/TablePages";
import { Form, Formik } from "formik";
import { useMemo, useState } from "react";
import { Alert, Breadcrumb, BreadcrumbItem, Button, Card, Col, Nav, Row, Tab, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import dashboard from "utils/api/dashboard";
import reserves from "utils/api/reserves";
import Role from "utils/enums/Role";
import { isRole } from "utils/functions/utilities";
import { useUser } from "utils/hooks/auth";
import { usePaginate } from "utils/hooks/paginate";
import useWindowDimensions from "utils/hooks/window";
import EstimatedPaymentsTable from "../../Plans/View/EstimatedPaymentsTable";
import ClientsPaymentHistory from "./History";
import clientsTablecolumns, {
  clientsTablecolumnVisibility,
  columnOrder,
} from "./tableConfig";
import ClientsTransfers from "./Tranfers";

type Props = {};

const ReserveClientsList = (props: Props) => {
  const [filter, setFilter] = useState({
    search: ""
  });
  const { id } = useParams();
  const { data: user } = useUser();
  const { t } = useTranslation(["common", "plans", "schools"]);
  const { data: selected } = useQuery(["reserves", id], () => reserves.view(id), {
    enabled: !!id && id != "paylater"
  })

  const { data: summary } = useQuery(["school-payment-summary"], () => dashboard.schoolPaymentsSummary(id!),
    {
      enabled: !!id && id != "paylater",
      placeholderData: {
        overdue: 0,
        upcoming: 0,
        remaining: 0
      }
    });


  const paginate = usePaginate({
    key: "reserves-clients",
    api: (args: any) => reserves.clients(id, args),
    filter
  })

  const { width } = useWindowDimensions();

  const table = useReactTable({
    data: paginate.data?.data || [],
    columns: useMemo(() => clientsTablecolumns(t), [t]),
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnOrder: useMemo(() => columnOrder(user?.role_id!), [width]),
      columnVisibility: useMemo(
        () => clientsTablecolumnVisibility(width, user?.role_id!),
        [width]
      ),
    },
  });

  function submit(values: any) {
    const newFilters: any = {};
    if (values.search) newFilters["search"] = values.search;
    setFilter(newFilters);
  }

  if (!id) return <>Choose a reserve first</>
  return (
    <>
      <Breadcrumb className="mb-3">
        <BreadcrumbItem linkAs="div">
          <Link to="/reserves">
            {isRole(Role.FI, user) ? t("accounts") : t("reserves")}
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{selected?.entity?.name}</BreadcrumbItem>
      </Breadcrumb>
      <Row className="gy-2 mt-0">
        <Tab.Container id="installments-tabs" defaultActiveKey="clients">
          <Col lg="12">
            <Row>
              <Col lg="6">
                <h5>{t('plans:installments')}</h5>
              </Col>
              <Col lg="6">
                <Nav variant="tabs" className="none-top-border fs-7 mb-3 justify-content-end">
                  <Nav.Item className="me-4">
                    <Nav.Link eventKey="clients">
                      {t("clients")}
                    </Nav.Link>
                  </Nav.Item>
                  {id != "paylater" && (
                    <>
                      <Nav.Item className="me-4">
                        <Nav.Link eventKey="transfers">
                          {t("transfers")}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="history">{t("payment_history")}</Nav.Link>
                      </Nav.Item>
                    </>
                  )}
                </Nav>
              </Col>
            </Row>
          </Col>
          <Col lg="12">
            <Tab.Content>
              <Tab.Pane eventKey="clients">
                <Row className="gy-3">
                  <Col lg={12}>
                    <h6 className="text-dark">{t('payment_summary')}</h6>
                  </Col>
                  {id != "paylater" &&
                    <>
                      <Col lg={4}>
                        <Alert variant="danger">
                          <div className="mb-3">{t("plans:overdue")}</div>
                          <div className="d-flex justify-content-between  fw-bold">
                            <p className="fs-4">
                              {summary?.overdue?.toLocaleString(undefined, {
                                minimumFractionDigits: 3,
                              })}
                              <sup className="mx-1">{t("common:kd")}</sup>
                            </p>
                          </div>
                        </Alert>
                      </Col>

                      <Col lg="4">
                        <Alert variant="white">
                          <div className="mb-3">{t("plans:upcoming_in_x_days", { days: 30 })}</div>
                          <div className="d-flex justify-content-between  fw-bold">
                            <p className="fs-4 text-dark">
                              {summary?.upcoming?.toLocaleString(undefined, {
                                minimumFractionDigits: 3,
                              })}
                              <sup className="mx-1">{t("common:kd")}</sup>
                            </p>
                          </div>
                        </Alert>
                      </Col>
                      <Col lg="4">
                        <Alert variant="white">
                          <div className="mb-3">{t("plans:remaining_installments")}</div>
                          <div className="d-flex justify-content-between  fw-bold">
                            <p className="fs-4 text-dark">
                              {summary?.remaining?.toLocaleString(undefined, {
                                minimumFractionDigits: 3,
                              })}
                              <sup className="mx-1">{t("common:kd")}</sup>
                            </p>
                          </div>
                        </Alert>
                      </Col>
                    </>
                  }
                  <Col lg="12">
                    <Card className="main-card mb-3">
                      <Card.Body>
                        <Row className="mb-3">
                          <Col lg="12">
                            <Formik
                              enableReinitialize
                              initialValues={{
                                search: filter.search,
                              }}
                              onSubmit={submit}
                            >
                              {(formikBag) => (
                                <Form>
                                  <Row className="d-flex justify-content-end flex-wrap">
                                    <Col lg="6" className="position-relative mb-3 mb-lg-0">
                                      <i
                                        style={{ zIndex: 1 }}
                                        className="position-absolute mt-2 bi bi-search fs-5 text-black-50 ms-3 py-2"
                                      ></i>
                                      <Input
                                        className="ps-5 mt-lg-0"
                                        name="search"
                                        placeholder={t("common:search")}
                                      />
                                    </Col>
                                    <Col lg="2" className="mb-3 mb-sm-0">
                                      <Button
                                        type="submit"
                                        className="w-100 h-100 p-3 px-4"
                                        variant="primary"
                                      >
                                        {t("common:apply")}
                                      </Button>
                                    </Col>
                                  </Row>
                                </Form>
                              )}
                            </Formik>
                          </Col>
                        </Row>
                        <Table className="mb-0">
                          <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                              <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                  <th key={header.id}>
                                    {header.isPlaceholder
                                      ? null
                                      : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                  </th>
                                ))}
                                <th>{t("actions")}</th>
                              </tr>
                            ))}
                          </thead>
                          <tbody>
                            {table.getRowModel().rows.map((row) => (
                              <tr key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                  <td key={cell.id}>
                                    {flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext()
                                    )}
                                  </td>
                                ))}
                                <td>
                                  <div className="d-flex">
                                    {id != "paylater" ?
                                      <Link state={
                                        {
                                          backReserve: {
                                            id: selected?.id,
                                            name: selected?.entity?.name
                                          }
                                        }
                                      } to={`/reserves/${row.original.reserve?.id}`}>
                                        <Icon name="openEye" />
                                      </Link>
                                      :
                                      <EstimatedPaymentsTable plan={row.original} showDate={false} />
                                    }
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <TablePages {...paginate} />
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Tab.Pane>
              {id != "paylater" && (
                <>
                  <Tab.Pane eventKey="transfers">
                    <ClientsTransfers />
                  </Tab.Pane>
                  <Tab.Pane eventKey="history">
                    <ClientsPaymentHistory />
                  </Tab.Pane>
                </>
              )}
            </Tab.Content>
          </Col>
        </Tab.Container>
      </Row>
    </>

  );
};

export default ReserveClientsList;
