import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  fi?: any;
}

const FIMethodModal = (props: Props) => {
  const { open, setOpen, fi } = props;
  const { t } = useTranslation(["common", "plans"]);

  return (
    <Modal
      show={open}
      onHide={() => setOpen(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{fi?.name || t('plans:financial_institution')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{fi?.method}</p>
      </Modal.Body>
      <Modal.Footer>
        <div className={`d-flex ${fi?.website ? 'justify-content-between' : 'justify-content-end'} w-100`}>
          {fi?.website && (
            <Button 
              variant="secondary"
              onClick={() => window.open(fi.website, '_blank')}
            >
              {t('common:visit_website')}
            </Button>
          )}
          <Button variant="secondary" onClick={() => setOpen(false)}>
            {t('common:close')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default FIMethodModal; 