import { useQuery } from "@tanstack/react-query";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { transactions } from "utils/api/transactions";
import { usePaginate } from "utils/hooks/paginate";
import TablePages from "components/widgets/TablePages";
import { Form, Formik } from "formik";
import Input from "components/inputs/Input";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Select from "components/inputs/Select";
import Amount from "components/widgets/Amount";
import TransactionDetail from "../Detail";
import Icon from "components/widgets/Icon";
import TransactionType from "utils/enums/TransactionType";
const ListTransactions = () => {
  const [filter, setFilter] = useState({
    type: Object.values(TransactionType).filter(type => type !== TransactionType.SERVICE_FEE)
  });
  const [selectedTransactionId, setSelectedTransactionId] = useState<number | null>(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  
  const paginate = usePaginate({
    key: "transactions",
    api: transactions.list,
    filter
  });

  const { data: filterOptions } = useQuery(["transactions-filterables"], () =>
    transactions.filterables(),
  );

  const { t } = useTranslation(["common", "plans", "transactions"]);

  function submit(values: any) {
    const newFilters: any = {};
    if (values.search) newFilters["search"] = values.search;
    if (values.status) newFilters["status"] = values.status;
    if (values.type) newFilters["type"] = values.type;
    if (values.date_from) newFilters["date_from"] = values.date_from;
    if (values.date_to) newFilters["date_to"] = values.date_to;
    setFilter(newFilters);
  }

  function handleViewTransaction(id: number) {
    setSelectedTransactionId(id);
    setShowDetailModal(true);
  }

  function handleCloseDetailModal() {
    setShowDetailModal(false);
    setSelectedTransactionId(null);
  }

  return (
    <>
      <Row>
        <Col lg="12">
          <Card className="main-card mb-3">
            <Card.Body>
              <Card.Title>
                <Formik
                  enableReinitialize
                  initialValues={{
                    search: "",
                    status: "",
                    date_from: "",
                    date_to: "",
                  }}
                  onSubmit={submit}
                >
                  {({ setFieldValue }) => (
                    <Form>
                      <Row className="mb-3">
                        <Col lg="6" className="">
                          <i
                            style={{ zIndex: 1 }}
                            className="position-absolute mt-2 bi bi-search fs-5 text-black-50 ms-3 py-2"
                          ></i>
                          <Input
                            className="ps-5 mt-lg-0"
                            name="search"
                            placeholder={t("common:search")}
                          />
                        </Col>
                        <Col lg="3">
                          <Input name="date_from" type="date" placeholder="From Date" />
                        </Col>
                        <Col lg="3">
                          <Input name="date_to" type="date" placeholder="To Date" />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="5">
                          <Select
                            name="status"
                            placeholder={t("transactions:status")}
                            options={filterOptions?.statuses?.map((status: any) => ({
                              label: status.label,
                              value: status.value,
                            })) || []}
                            labelKey="label"
                            valueKey="value"
                          />
                        </Col>
                        <Col lg="5">
                          <Select
                            name="type"
                            placeholder={t("transactions:type")}
                            options={filterOptions?.types?.map((type: any) => ({
                              label: type.label,
                              value: type.value,
                            })) || []}
                            labelKey="label"
                            valueKey="value"
                          />
                        </Col>
                        <Col lg="2">
                          <Button type="submit" color="primary" className="h-100 w-100">
                            {t("common:search")}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Title>
              <Table striped className="mb-0">
                <thead>
                  <tr>
                    <th>{t("common:id")}</th>
                    <th>TID</th>
                    <th>{t("plans:amount")}</th>
                    <th>{t("common:type")}</th>
                    <th>{t("plans:status")}</th>
                    <th>{t("common:date")}</th>
                    <th>{t("common:actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {paginate?.data?.data?.map((item: any) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.tid}</td>
                      <td>
                        <Amount amount={item.amount} />
                      </td>
                      <td>{item.type}</td>
                      <td>
                        <span className={`badge bg-${getStatusColor(item.status)}`}>
                          {item.status}
                        </span>
                      </td>
                      <td>{new Date(item.created_at).toLocaleDateString()}</td>
                      <td>
                        <div
                          className="me-2"
                          onClick={() => handleViewTransaction(item.id)}
                        >
                          <Icon name="openEye" size={16} />
                        </div>
                        {item.status === "completed" && (
                          <Button
                            size="sm"
                            color="danger"
                            onClick={() => handleRefundRequest(item.id)}
                          >
                            {t("transactions:request_refund")}
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <TablePages {...paginate} />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <TransactionDetail 
        show={showDetailModal}
        onHide={handleCloseDetailModal}
        transactionId={selectedTransactionId}
      />
    </>
  );
};

// Helper function to determine badge color based on status
function getStatusColor(status: string): string {
  switch (status?.toLowerCase()) {
    case "completed":
      return "success";
    case "pending":
      return "warning";
    case "failed":
      return "danger";
    case "refunded":
      return "info";
    default:
      return "secondary";
  }
}

// Function to handle refund request
function handleRefundRequest(id: number) {
  if (window.confirm("Are you sure you want to request a refund?")) {
    transactions.requestRefund(id)
      .then(() => {
        alert("Refund request submitted successfully");
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error requesting refund:", error);
        alert("Failed to request refund. Please try again.");
      });
  }
}

export default ListTransactions; 