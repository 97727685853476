import React, { useEffect, useState } from "react";
import { Modal, Button, Table, Card, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { transactions } from "utils/api/transactions";
import { reserveTransactions } from "utils/api/reserveTransactions";
import Amount from "components/widgets/Amount";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

interface TransactionDetailProps {
  show: boolean;
  onHide: () => void;
  transactionId: number | null;
}

const TransactionDetail: React.FC<TransactionDetailProps> = ({
  show,
  onHide,
  transactionId,
}) => {
  const { t } = useTranslation(["common"]);
  const queryClient = useQueryClient();
  const [isSettling, setIsSettling] = useState(false);
  
  const { data: transaction, isLoading } = useQuery(
    ["transaction", transactionId],
    () => transactions.get(transactionId!),
    {
      enabled: !!transactionId && show,
      refetchOnWindowFocus: false,
    }
  );

  const handleSettle = async () => {
    if (!transaction?.reserveTransaction?.id) return;
    
    try {
      setIsSettling(true);
      await reserveTransactions.settle(transaction.reserveTransaction.id.toString());
      toast.success(t("common:settled"));
      queryClient.invalidateQueries(["transaction", transactionId]);
    } catch (error) {
      console.error("Error settling transaction:", error);
      toast.error("Failed to settle transaction");
    } finally {
      setIsSettling(false);
    }
  };

  // Helper function to check if all related transactions are successful
  const canSettle = () => {
    if (!transaction?.reserveTransaction?.transactions?.length) return false;

    // Check if the reserve transaction is already settled
    if (transaction.reserveTransaction.status?.toLowerCase() === "settled") return false;
    
    // Check if all transactions have a success status
    return transaction.reserveTransaction.transactions.every(
      (tx: any) => tx.status?.toLowerCase() === "completed" || tx.status?.toLowerCase() === "success"
    );
  };

  if (isLoading) {
    return (
      <Modal show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">{t("common:transaction_details")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center py-4">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="fs-5">{t("common:transaction_details")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="fs-6">
        {transaction && (
          <>
            <Card className="mb-4">
              <Card.Header>
                <h5 className="mb-0 fs-6">{t("common:transaction_info")}</h5>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md={6}>
                    <p className="fs-6">
                      <strong>{t("common:id")}:</strong> {transaction.id}
                    </p>
                    <p className="fs-6">
                      <strong>TID:</strong> {transaction.tid}
                    </p>
                    <p className="fs-6">
                      <strong>{t("common:status")}:</strong>{" "}
                      <span className={`badge bg-${getStatusColor(transaction.status)}`}>
                        {transaction.status}
                      </span>
                    </p>
                  </Col>
                  <Col md={6}>
                    <p className="fs-6">
                      <strong>{t("plans:amount")}:</strong>{" "}
                      <Amount amount={transaction.amount} />
                    </p>
                    <p className="fs-6">
                      <strong>{t("common:date")}:</strong>{" "}
                      {new Date(transaction.created_at).toLocaleDateString()}
                    </p>
                    {transaction.payment_date && (
                      <p className="fs-6">
                        <strong>{t("common:payment_date")}:</strong>{" "}
                        {new Date(transaction.payment_date).toLocaleDateString()}
                      </p>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {transaction.reserveTransaction && (
              <Card className="mb-4">
                <Card.Header>
                  <h5 className="mb-0 fs-6">{t("common:reserve_transaction")}</h5>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      <p className="fs-6">
                        <strong>{t("common:id")}:</strong> {transaction.reserveTransaction.id}
                      </p>
                      <p className="fs-6">
                        <strong>{t("common:from_reserve")}:</strong>{" "}
                        {transaction.reserveTransaction.from_reserve_id}
                      </p>
                    </Col>
                    <Col md={6}>
                      <p className="fs-6">
                        <strong>{t("common:to_reserve")}:</strong>{" "}
                        {transaction.reserveTransaction.to_reserve_id}
                      </p>
                      <p className="fs-6">
                        <strong>{t("plans:amount")}:</strong>{" "}
                        <Amount amount={transaction.reserveTransaction.amount} />
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            )}

            {transaction.reserveTransaction?.transactions?.length > 0 && (
              <Card>
                <Card.Header>
                  <h5 className="mb-0 fs-6">{t("common:related_transactions")}</h5>
                </Card.Header>
                <Card.Body>
                  <Table striped bordered responsive>
                    <thead>
                      <tr>
                        <th>{t("common:id")}</th>
                        <th>TID</th>
                        <th>{t("plans:amount")}</th>
                        <th>{t("plans:status")}</th>
                        <th>{t("common:date")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transaction.reserveTransaction.transactions.map((relatedTransaction: any) => (
                        <tr key={relatedTransaction.id}>
                          <td>{relatedTransaction.id}</td>
                          <td>{relatedTransaction.tid}</td>
                          <td>
                            <Amount amount={relatedTransaction.amount} />
                          </td>
                          <td>
                            <span className={`badge bg-${getStatusColor(relatedTransaction.status)}`}>
                              {relatedTransaction.status}
                            </span>
                          </td>
                          <td>{new Date(relatedTransaction.created_at).toLocaleDateString()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {transaction && canSettle() && (
          <Button 
            variant="primary" 
            onClick={handleSettle} 
            disabled={isSettling}
            className="fs-6"
          >
            {isSettling ? (
              <>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                {t("common:settle")}
              </>
            ) : (
              t("common:settle")
            )}
          </Button>
        )}
        <Button variant="secondary" onClick={onHide} className="fs-6">
          {t("common:close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// Helper function to determine badge color based on status
function getStatusColor(status: string): string {
  switch (status?.toLowerCase()) {
    case "completed":
      return "success";
    case "pending":
      return "warning";
    case "failed":
      return "danger";
    case "refunded":
      return "info";
    default:
      return "secondary";
  }
}

export default TransactionDetail; 