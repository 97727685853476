import React from "react";
import { Route, Routes } from "react-router-dom";
import { ListTransactions } from "./index";

const TransactionsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ListTransactions />} />
    </Routes>
  );
};

export default TransactionsRoutes; 