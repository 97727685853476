import client from "../api";

export function list({
  queryKey,
  signal,
  ...params
}: any | undefined = undefined): Promise<any> {
  return client.get("transactions", {
    params,
  });
}

export function requestRefund(id: number): Promise<any> {
  return client.get(`transactions/request-refund/${id}`,);
}

export function approveRefund(id: number): Promise<any> {
  return client.get(`transactions/approve-refund/${id}`);
}

async function generatedDocument(
  id: number,
  type: string,
) {
  return client.get(
    `transactions/${id}/generate/${type}`,
    {
      responseType: "blob",
    }
  ) as any;
}

function download(id: string | undefined, startDate: string) {
  return client.get(`transactions/download/${id}/${startDate}`) as any;
}

function paymentLink(ids: string[], referral_code?: string): Promise<any> {
  return client.post(`transactions/link`, { ids, referral_code });
}

function filterables(): Promise<any> {
  return client.get(`transactions/filterables`);
}

function estimate(id: number): Promise<{
  amount: number;
  discounted: boolean;
}> {
  return client.get(`transactions/estimate/${id}`);
}

// Get a single transaction by ID
function get(id: number): Promise<any> {
  return client.get(`transactions/${id}`);
}

// refund function
function refund(id: number) {
  return client.get(`transactions/refund/${id}`);
}

export const transactions = {
  list,
  requestRefund,
  approveRefund,
  refund,
  generatedDocument,
  download,
  paymentLink,
  filterables,
  estimate,
  get,
};
