import MediumPieCard from 'components/widgets/Dashboard/MediumPieCard'
import React from 'react'
import { Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import dashboardPaynow from 'utils/api/dashboard-paynow'
import { graphColors } from 'utils/constants'

type Props = {}

const AdminPaynowTransactionVolume = (props: Props) => {
    const { t } = useTranslation(["common"]);
    const { data, isFetching } = useQuery({
        queryKey: ["dashboard", "admin-paynow-transaction-volume"],
        queryFn: dashboardPaynow.adminTransactionVolume,
        placeholderData: [],
    });

    return (
        <Col lg={6} className="mb-3">
            <MediumPieCard
                loading={isFetching}
                title={t("plans:total_plans")}
                currency
                data={data?.map((item: any, index: number) => ({
                    name: item.name,
                    value: +item.value,
                    color: graphColors[index]
                }))}
            />
        </Col>
        
    )
}

export default AdminPaynowTransactionVolume