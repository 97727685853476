import client from "../api";

export function settledButNotTransferred(): Promise<any> {
  console.log("settledButNotTransferred");
  return client.get(`developers/settled-but-not-transferred`);
}

export function reconcileTransferDates(): Promise<any> {
  console.log("reconcileTransferDates");
  return client.get(`developers/reconcile-transfer-dates`);
}

export function releaseTransactions(transactionIds: string[]): Promise<any> {
  return client.post(`developers/release-transactions`, { transactionIds });
}

export const developers = {
  settledButNotTransferred,
  reconcileTransferDates,
  releaseTransactions
};

export default developers;
