import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import AdminPaynowTransactionVolume from './TransactionVolume'
import AdminPaynowRevenueGenerated from './RevenueGenerated'
import RoleBasedRenderer from 'containers/common/RoleBasedRenderer'
import SchoolPendingTransactions from 'containers/Dashboard/SchoolWidgets/Paynow/SchoolPendingTransactions'

type Props = {}

const DashboardPaynow = (props: Props) => {
    const { t } = useTranslation(["common"]);

    return (
        <Row>
            <Col lg={12}>
                <h4 className="text-dark fw-bold">
                    {t("paynow_overview")}
                </h4>
                <h5 className="fw-light">{t("paynow_overview_desc")}</h5>
            </Col>
            <AdminPaynowTransactionVolume />
            <RoleBasedRenderer
                admin={<AdminPaynowRevenueGenerated />}
                school={<SchoolPendingTransactions />}
            />
        </Row>
    )
}

export default DashboardPaynow