import { useTranslation } from "react-i18next";
import TransactionType from "utils/enums/TransactionType";

function TransactionTypeRenderer(props) {
  const { type } = props;
  const { t } = useTranslation(["plans", "common"]);
  let label = t("common:pending");

  let color = "gray";
  switch (type) {
    case TransactionType.COLLECTION:
    case TransactionType.PAYNOW:
      label = t("common:payment");
      break;
    case TransactionType.PAYLATER:
      label = t("common:paylater");
      break;
    default:
      color = "primary";
      label = t("common:reserve");
      break;
  }

  return (
    <div className="widget-content-right">
      <div className={`badge bg-${color}`}>{label}</div>
    </div>
  );
}

export default TransactionTypeRenderer;
